/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

//load large images into lightbox
const loadMImages = () => {
    sw.negativeClass = 'negative';
    const trigger = sw.imgTarget.parent();
    trigger.off().on('click', function () {
        sw.path = $(this).attr('href').replace('img-m', 'img-l');
        sw.title = 'Luke Harby - Photographer';
        sw.overlay.addClass(sw.loadingClass);
        $.ajax({
            type: 'GET',
            url: `${sw.path}`,
            xhrFields: {
                responseType: 'blob'
            },
            success(data) {
                const urlObj = window.URL || window.webkitURL;
                const src = urlObj.createObjectURL(data);
                sw.overlayImg.attr('src', src);
            },
            error(status) {
                console.log(status.statusText);
            }
        });
        sw.imgTarget.hasClass(sw.negativeClass)
            ? sw.overlayImg.addClass(sw.negativeClass)
            : sw.overlayImg.removeClass(sw.negativeClass);
        return false;
    });
};

export default loadMImages;
