/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

// hide footer on mobile
const detachFooter = () => {
    sw.descriptionWrapper = $('.descriptionWrapper');
    if (sw.isMobile && sw.sliderWrapper.length) {
        sw.descriptionWrapper.detach().appendTo(sw.sliderWrapper);
    }
};

export default detachFooter;
