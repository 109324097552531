/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

const wrapper = $('.blog-archive');
const postContent = wrapper.find('#tumblr-posts');
const pageStr = '?tagged=';
let tag;
let urlChanged;

// retrieve tumblr posts
const getTumblrPostsArchive = () => {
    const blogPagination = wrapper.find('.blogPagination');
    history.pushState(null, null, pageStr + tag);
    sw.pageNo = parseInt(document.location.href.split('=')[1]);
    const protocol = document.location.protocol;
    const baseURL = 'api.tumblr.com/v2/blog/';
    const blog = 'slack-wise.tumblr.com';
    let limit = 20;
    const output = $('.output');
    const retrieveMore = function (offset) {
        $.ajax({
            url: `${protocol}//${baseURL}${blog}/posts?offset=${offset}`,
            dataType: 'jsonp',
            data: {
                api_key: sw._site.tumblrAPIKey,
                tag
            },
            success(results) {
                let i = 0;
                const totalPosts = results.response.total_posts;
                const postLength = results.response.posts.length;
                if (totalPosts === 0) {
                    const tag = $('.tag').val();
                    postContent.append(`<li class="full-width">Sorry no posts tagged ${tag}</li>`);
                    output.empty();
                } else {
                    output.text(`Total posts: ${totalPosts}`);
                }
                while (i < postLength) {
                    const postData = results.response.posts[i];
                    const type = postData.type;
                    const photoset = postData.photoset_layout;
                    let date = results.response.posts[i].date;
                    const returnFormattedDate = () => {
                        date = new Date(date.replace(/-/g, '/'));
                        let day = date.getUTCDate();
                        let year = date.getUTCFullYear();
                        year = year.toString().substr(-2);
                        var month = new Array(12);
                        month[0] = '01';
                        month[1] = '02';
                        month[2] = '03';
                        month[3] = '04';
                        month[4] = '05';
                        month[5] = '06';
                        month[6] = '07';
                        month[7] = '08';
                        month[8] = '09';
                        month[9] = '10';
                        month[10] = '11';
                        month[11] = '12';
                        month = month[date.getUTCMonth()];
                        if (day <= 9) {
                            day = `0${day}`;
                        }
                        return (date = `${day}.${month}.${year}`);
                    };
                    returnFormattedDate();
                    if (photoset != null) {
                        const postURL = postData.post_url;
                        const photos = postData.photos;
                        const caption = postData.caption;
                        for (let j = 0; j < 1; j++) {
                            let imgURL;
                            if (photos[j].alt_sizes) {
                                const lastItem = photos[j].alt_sizes.length - 3;
                                imgURL = photos[j].alt_sizes[lastItem].url;
                            } else {
                                continue;
                            }
                            postContent.append(
                                `<li class='photoset'><a href=${postURL}><img src=${imgURL} /><div class="posAbs"><p>${caption}</p></div></a></li>`
                            );
                        }
                    } else if (type == 'photo') {
                        const postURL = postData.post_url;
                        const photos = postData.photos;
                        const caption = postData.caption;
                        for (let j = 0; j < photos.length; j++) {
                            let imgURL;
                            if (photos[j].alt_sizes) {
                                const lastItem = photos[j].alt_sizes.length - 3;
                                imgURL = photos[j].alt_sizes[lastItem].url;
                            } else {
                                continue;
                            }
                            postContent.append(
                                `<li class=${type}><a href=${postURL}><img src=${imgURL} /><div class="posAbs"><p>${caption}</p></div></a></li>`
                            );
                        }
                    } else if (type == 'text') {
                        const postURL = postData.post_url;
                        const content = postData.body;
                        postContent.append(
                            `<li class=${type}>
                                <button data-href=${postURL} data-target="_blank" class="button-href">
                                    ${content}
                                </button>
                            </li>`
                        );
                    } else if (type == 'video') {
                        const postURL = postData.post_url;
                        const video = postData.player[2].embed_code;
                        const caption = postData.caption;
                        postContent.append(
                            `<li class=${type}>
                                <a href=${postURL}>
                                    ${video}
                                    <p>${caption}</p>
                                </a>
                            </li>`
                        );
                        $('video').attr('controls', 'controls');
                    } else if (type == 'audio') {
                        const postURL = postData.post_url;
                        const caption = postData.caption;
                        const content = postData.player;
                        if (caption !== ' ') {
                            postContent.append(
                                `<li class=${type}><a href=${postURL}>${content}<p>${caption}</p></a></li>`
                            );
                        } else {
                            postContent.append(
                                `<li class=${type}><a href=${postURL}>${content}</a></li>`
                            );
                        }
                    } else if (type == 'quote') {
                        const postURL = postData.post_url;
                        const content = postData.text;
                        const source = postData.source;
                        if (source !== '') {
                            postContent.append(
                                `<li class=${type}><a href=${postURL}><p>${content}</p><p class="source">- ${source}</p></a></li>`
                            );
                        } else {
                            postContent.append(
                                `<li class=${type}><a href=${postURL}><p>${content}</p></a></li>`
                            );
                        }
                    } else if (type == 'chat') {
                        const postURL = postData.post_url;
                        const dialogue = postData.dialogue;
                        const question = `<p>${dialogue[0].phrase}</p>`;
                        const answer = `<p>${dialogue[1].phrase}</p>`;
                        postContent.append(
                            `<li class=${type}><a href=${postURL}>${question}${answer}</a><li>`
                        );
                    } else if (type == 'answer') {
                        const postURL = postData.post_url;
                        const content = postData.answer;
                        postContent.append(
                            `<li class=${type}><button data-href=${postURL} data-target="_blank" class="button-href">${content}</button></li>`
                        );
                    } else if (type == 'link') {
                        const postURL = postData.post_url;
                        const content = postData.description;
                        postContent.append(
                            `<li class=${type}>
                                <a href=${postURL}>
                                    ${content}
                                </a>
                            </li>`
                        );
                    } else {
                        console.log('other post type', type);
                    }
                    i++;
                }
                if (totalPosts >= offset && postLength !== 0) {
                    retrieveMore(offset + limit);
                }
                postContent.find('a').attr('target', '_blank');
                postContent.find('.button-href a').removeAttr('href target');
            },
            error() {
                postContent.append('<li>Sorry, there was an error</li>');
                blogPagination.hide();
            }
        });
    };
    retrieveMore(0);
};

const attachTagEvent = () => {
    const input = wrapper.find('.tag');
    const tags = wrapper.find('.tags');
    const clear = wrapper.find('.clear');
    tags.on('click', (event) => {
        postContent.empty();
        tag = $('.tag').val();
        if (tag.length <= 0) {
            return;
        } else {
            getTumblrPostsArchive();
        }
        event.preventDefault();
    });
    input.on('input change', () => {
        tag = $('.tag').val();
        if (tag.length <= 0) {
            clear.addClass(sw.visibleClass);
        } else {
            clear.removeClass(sw.visibleClass);
        }
    });
    clear.on('click', (event) => {
        input.val('');
        clear.addClass(sw.visibleClass);
        event.preventDefault();
    });
    sw.dom.keypress(function (event) {
        tag = $('.tag').val();
        const keycode = event.keyCode ? event.keyCode : event.which;
        if (keycode == '13' && tag.length >= 1) {
            tags.click();
        }
    });
    sw.body.on('click', '.button-href', function () {
        const href = $(this).attr('data-href');
        const target = $(this).attr('data-target');
        window.open(href, target);
    });
    window.onpopstate = function () {
        urlChanged = true;
        tag = sw.href.split('=')[1];
        console.log(tag, urlChanged);
    };
    if (sw.href.match('tagged=') || urlChanged) {
        tag = sw.href.split('=')[1];
        getTumblrPostsArchive();
    }
};

export { attachTagEvent };
