/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

// getTumblrPosts
let totalPosts;
let limit;
let pageTotal;

sw.isAPIPage = false;
if (
    'studio' === sw.primaryDir.toLowerCase() ||
    'news' === sw.primaryDir.toLowerCase() ||
    'practice' === sw.primaryDir.toLowerCase()
) {
    sw.isAPIPage = true;
}

// retrieve tumblr posts
const getTumblrPosts = () => {
    const postContent = $('#tumblr-posts');
    const blogPagination = $('.blogPagination');
    const pageStr = '?page=';
    if (!sw.href.match('page=')) {
        history.replaceState(null, null, pageStr + 0);
    }
    sw.pageNo = parseInt(document.location.href.split('=')[1]);
    const protocol = document.location.protocol;
    const baseURL = 'api.tumblr.com/v2/blog/';
    const blog = 'slack-wise.tumblr.com';
    limit = 13;
    let offSet = limit * sw.pageNo;
    const mapDirectory = () => {
        return sw.primaryDir === 'studio' ? 'studiolh' : sw.primaryDir;
    };
    $.ajax({
        url: `${protocol}//${baseURL}${blog}/posts?limit=${limit}&offset=${offSet}`,
        dataType: 'jsonp',
        data: {
            api_key: sw._site.tumblrAPIKey,
            tag: mapDirectory(),
            offset: offSet
        },
        success(results) {
            let i = 0;
            const set = [];
            const postLength = results.response.posts.length;
            totalPosts = results.response.total_posts;
            while (i < postLength) {
                const postData = results.response.posts[i];
                const type = postData.type;
                const photoset = postData.photoset_layout;
                const postURL = postData.post_url;
                let date = results.response.posts[i].date;
                const returnFormattedDate = () => {
                    date = new Date(date.replace(/-/g, '/'));
                    let day = date.getUTCDate();
                    let year = date.getUTCFullYear();
                    year = year.toString().substr(-2);
                    var month = new Array(12);
                    month[0] = '01';
                    month[1] = '02';
                    month[2] = '03';
                    month[3] = '04';
                    month[4] = '05';
                    month[5] = '06';
                    month[6] = '07';
                    month[7] = '08';
                    month[8] = '09';
                    month[9] = '10';
                    month[10] = '11';
                    month[11] = '12';
                    month = month[date.getUTCMonth()];
                    if (day <= 9) {
                        day = `0${day}`;
                    }
                    return (date = `${day}.${month}.${year}`);
                };
                returnFormattedDate();
                if (photoset != null) {
                    const photos = postData.photos;
                    const caption = postData.caption;
                    for (let j = 0; j < photos.length; j++) {
                        let imgURL;
                        if (photos[j].alt_sizes[0]) {
                            imgURL = photos[j].alt_sizes[0].url;
                        } else {
                            continue;
                        }
                        set.push(`<ul><li><img src=${imgURL} /></li></ul>`);
                    }
                    if (caption != ' ') {
                        set.push(
                            `<ul><li><p>${caption}</p><p><a href=${postURL}><span class="icon-link ion-ios-infinite-outline"></span>${date}</a></p></li></ul>`
                        );
                        const subUl = document.createElement('li');
                        for (let k = 0; k < set.length; k++) {
                            $(subUl).append(set[k]);
                        }
                        $(subUl).addClass('photoset');
                        postContent.append(subUl);
                        set.length = 0;
                    }
                } else if (type == 'photo') {
                    const photos = postData.photos;
                    const caption = postData.caption;
                    for (let j = 0; j < photos.length; j++) {
                        let imgURL;
                        if (photos[j].alt_sizes[0]) {
                            imgURL = photos[j].alt_sizes[0].url;
                        } else {
                            continue;
                        }
                        postContent.append(
                            `<li class=${type}><img src=${imgURL} /><p>${caption}</p><p><a href=${postURL}><span class="icon-link ion-ios-infinite-outline"></span>${date}</a></p></li>`
                        );
                    }
                } else if (type == 'video') {
                    const video = postData.player[2].embed_code;
                    const caption = postData.caption;
                    postContent.append(
                        `<li class=${type}>
                            ${video}
                            <p>${caption}</p>
                            <p><a href=${postURL}><span class="icon-link ion-ios-infinite-outline"></span>${date}</a></p>
                        </li>`
                    );
                    $('video').attr('controls', 'controls');
                } else if (type == 'text') {
                    const content = postData.body;
                    postContent.append(
                        `<li class=${type}>${content}<p><a href=${postURL}><span class="icon-link ion-ios-infinite-outline"></span>${date}</a></p></li>`
                    );
                } else if (type == 'audio') {
                    const caption = postData.caption;
                    const content = postData.player;
                    if (caption !== ' ') {
                        postContent.append(
                            `<li class=${type}>${content}<p>${caption}</p><p><a href=${postURL}><span class="icon-link ion-ios-infinite-outline"></span>${date}</a></p></li>`
                        );
                    } else {
                        postContent.append(
                            `<li class=${type}>${content}<p><a href=${postURL}><span class="icon-link ion-ios-infinite-outline"></span>${date}</a></p></li>`
                        );
                    }
                } else if (type == 'quote') {
                    const content = postData.text;
                    const source = postData.source;
                    if (source !== '') {
                        postContent.append(
                            `<li class=${type}><p>${content}</p><p class="source">- ${source}</p><p><a href=${postURL}><span class="icon-link ion-ios-infinite-outline"></span>${date}</a></p></li>`
                        );
                    } else {
                        postContent.append(
                            `<li class=${type}><p>${content}</p><p><a href=${postURL}><span class="icon-link ion-ios-infinite-outline"></span>${date}</a></p></li>`
                        );
                    }
                } else if (type == 'chat') {
                    const dialogue = postData.dialogue;
                    const wrapper = $('<li>');
                    for (let i = 0; i < dialogue.length; i++) {
                        $(wrapper).append(`<p>${dialogue[i].phrase}</p>`);
                    }
                    $(wrapper).append(
                        `<p><a href=${postURL}><span class="icon-link ion-ios-infinite-outline"></span>${date}</a></p>`
                    );
                    wrapper.addClass(`${type}`);
                    postContent.append(wrapper);
                } else if (type == 'answer') {
                    const content = postData.answer;
                    postContent.append(
                        `<li class=${type}>${content}<p><a href=${postURL}><span class="icon-link ion-ios-infinite-outline"></span>${date}</a></p></li>`
                    );
                } else if (type == 'link') {
                    const content = postData.description;
                    const url = postData.url;
                    const photos = postData.photos;
                    for (let j = 0; j < photos.length; j++) {
                        let imgURL;
                        if (photos[j].alt_sizes[0]) {
                            imgURL = photos[j].alt_sizes[0].url;
                        } else {
                            continue;
                        }
                        postContent.append(
                            `<li class=${type}>
                                ${content}
                                <a href="${url}"><img src=${imgURL} /><p>${url}</p></a>
                                <p><a href=${postURL}><span class="icon-link ion-ios-infinite-outline"></span>${date}</a></p>
                            </li>`
                        );
                    }
                } else {
                    console.log('other post type', type);
                }
                i++;
            }
            postContent.find('a').attr('target', '_blank');
            chNav();
            updateTotalPosts();
        },
        error() {
            postContent.append('<li>Sorry, there was an error</li>');
            blogPagination.hide();
        }
    });

    // create dynamic navigation
    const chNav = () => {
        const navPrev = $('.prev');
        const navNext = $('.next');
        const pageNoWrapper = $('.page-number');
        let basePageNo;
        $(window).on('load', () => {
            basePageNo = sw.pageNo + 1;
            pageTotal = Math.ceil(totalPosts / limit);
            if (sw.pageNo === 0) {
                navPrev.remove();
            }
            if (pageTotal <= basePageNo) {
                navNext.remove();
            }
            navPrev.attr('href', () => {
                navPrev.attr('href', pageStr + (sw.pageNo - 1));
            });
            navNext.attr('href', () => {
                navNext.attr('href', pageStr + basePageNo);
            });
        });
        pageNoWrapper.text(sw.pageNo + 1);
    };
    const updateTotalPosts = () => {
        pageTotal = Math.ceil(totalPosts / limit);
        const pageTotalWrapper = $('.page-total');
        pageTotalWrapper.text(pageTotal);
    };
};

export default getTumblrPosts;
