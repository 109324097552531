/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

// close slider
const closeSlider = () => {
    const activeClass = 'active';
    const hiddenClass = 'hidden';
    const noScrollClass = 'no-scroll';
    const plusClass = 'nav-plus';
    const minusClass = 'nav-minus';
    sw.hideSlider = $('.close');
    sw.footerTrigger = $('.footer-trigger');
    sw.hideSlider.on('click', () => {
        sw.sliderWrapper.fadeOut(sw._site.speed.default);
        sw.images.removeClass(activeClass).hide();
        sw.footerContent.addClass(hiddenClass);
        sw.footerTrigger.removeClass(minusClass).addClass(plusClass);
        sw.sliderActive = false;
        sw.dom.removeClass(noScrollClass);
        return false;
    });
    $(sw.body).on('keydown', (e) => {
        if (sw.sliderActive) {
            if (e.keyCode === 27) {
                sw.sliderWrapper.fadeOut(sw._site.speed.default);
                sw.images.removeClass(activeClass).hide();
                sw.footerContent.addClass(hiddenClass);
                sw.footerTrigger.removeClass(minusClass).addClass(plusClass);
                sw.dom.removeClass(noScrollClass);
                sw.sliderActive = false;
            }
        }
    });
};

export default closeSlider;
