/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

// prevent tracking
const preventTracking = () => {
    const originalEventListener = document.addEventListener;
    document.addEventListener = function (a, b) {
        if (b.toString().indexOf('messageHandlers.fb_getSelection') >= -1) {
            return null;
        }
        return originalEventListener.apply(this, arguments);
    };
};

export default preventTracking;
