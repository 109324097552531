/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

// toggle footer
const toggleFooter = () => {
    const plusClass = 'nav-plus';
    const minusClass = 'nav-minus';
    sw.footerTrigger = $('.footer-trigger');
    sw.footerDescriptionTrigger = $('.closeDescription');
    sw.trigger = $.merge(sw.footerTrigger, sw.footerDescriptionTrigger);
    sw.footerContent = $('.footer-description');
    sw.trigger.on('click', function () {
        if ($(sw.footerContent).hasClass('hidden')) {
            sw.footerContent.removeClass('hidden');
            sw.footerTrigger.removeClass(plusClass).addClass(minusClass);
        } else {
            sw.footerContent.addClass('hidden');
            sw.footerTrigger.removeClass(minusClass).addClass(plusClass);
        }
        return false;
    });
};

export default toggleFooter;
