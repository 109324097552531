import styles from './scss/main.scss';

// general
import { utils } from './js/utils';
import refresh from './js/refresh';
import accordion from './js/accordion';
import { closeOverlay } from './js/closeOverlay';
import toggleFooter from './js/toggleFooter';
import legalTrigger from './js/legalTrigger';
import initModal from './js/modal';
import cookies from './js/cookies';
import pagination from './js/pagination';
import siteUtils from './js/siteUtils';

// work
import loadImages from './js/loadImages';
import loadMImages from './js/loadMImages';
import hoverLinks from './js/hoverLinks';

// mobile
import mobileNavigation from './js/mobileNavigation';
import loadMImagesMobile from './js/loadMImagesMobile';
import detachFooter from './js/detachFooter';

// slider
import sliderCallback from './js/sliderCallback';
import mobileSlider from './js/mobileSlider';
import closeSlider from './js/closeSlider';

// contact
import formSubmit from './js/formSubmit';

// shop
import shopAddCondition from './js/shopAddCondition';
import shopCTAText from './js/shopCTAText';

// blog
import getTumblrPosts from './js/getTumblrPosts';
import { attachTagEvent } from './js/getTumblrPostsArchive';
import blogLinks from './js/blogLinks';
import blogremoveFormatting from './js/blogremoveFormatting';

// random
import { randomFilter } from './js/randomFilter';
import { chromeWindow } from './js/chromeWindow';
import { zoetrope } from './js/zoetrope';

// contraband
import './js/deleteMedia';

// tracking
import preventTracking from './js/preventTracking';

// forms
import formUtils from './js/formUtils';

$(document).ready(() => {
    //sw();
    utils();
    refresh();
    accordion();
    closeOverlay();
    toggleFooter();
    legalTrigger();
    initModal();
    cookies();
    pagination();
    mobileNavigation();
    detachFooter();
    sliderCallback();
    closeSlider();
    formSubmit();
    formUtils();
    blogLinks();
    blogremoveFormatting();
    siteUtils();
    preventTracking();

    if (sw.dom.hasClass('work') || sw.dom.hasClass('collaboration')) {
        loadImages();
        loadMImages();
        hoverLinks();
        loadMImagesMobile();
    }

    if (!sw.isBlog && sw.slider.length) {
        mobileSlider();
    }

    if (sw.isAPIPage) {
        getTumblrPosts();
    }

    if (sw.dom.hasClass('blog-archive')) {
        attachTagEvent();
    }

    if (sw.dom.hasClass('dual')) {
        randomFilter();
    }

    if (sw.dom.hasClass('chrome-window')) {
        chromeWindow();
    }

    if (sw.dom.hasClass('zoetrope')) {
        zoetrope();
    }

    if (sw.isShop) {
        shopAddCondition();
        shopCTAText();
    }
});
