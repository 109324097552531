/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

// blog removeFormatting
const removeFormatting = () => {
    var content = $('.tumblr .post').find('p, span');
    content.removeAttr('style');
};

export default removeFormatting;
