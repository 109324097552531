/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

import { restrictIllegalChars } from './utils';

// form utils
const formUtils = (event) => {
    const input = $('.textinput, .textareainput');
    restrictIllegalChars(input, event);
};

export default formUtils;
