/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

// form submission
const formSubmit = () => {
    const page = $('.contact, .submit');
    const form = page.find('.contact-form');
    const trigger = page.find('.submit');
    const action = 'confirm.cfm';
    trigger.on('click', (e) => {
        if (grecaptcha.getResponse() === '') {
            e.preventDefault();
            return;
        } else {
            form.attr('action', action);
            form.submit();
        }
    });
};

export default formSubmit;
