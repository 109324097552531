/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

// shop condition
const shopAddCondition = () => {
    const wrapper = $('.woocommerce-product-attributes-item--attribute_pa_condition').find('th');
    if (wrapper.text() === 'Condition') {
        wrapper.wrap('<a href="shop-condition.html" class="modal condition-modal" />');
    }
};

export default shopAddCondition;
