/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

// navigation for mobile
const mobileNavigation = () => {
    sw.header = $('.navigation-header');
    sw.trigger = $('.trigger');
    sw.triggerA = sw.trigger.find('a');
    sw.navItems = $('#mainNav li').not(sw.trigger);
    sw.plus = 'nav-plus';
    sw.minus = 'nav-minus';
    const noScrollClass = 'no-scroll';
    sw.trigger.on('click', () => {
        if (sw.navItems.css('display') == 'none') {
            sw.navItems.fadeIn(sw._site.speed.default);
            sw.triggerA.removeClass(sw.plus).addClass(sw.minus);
            sw.header.addClass('open');
            sw.dom.addClass(noScrollClass);
        } else {
            sw.navItems.fadeOut(sw._site.speed.default);
            sw.triggerA.removeClass(sw.minus).addClass(sw.plus);
            sw.header.removeClass('open');
            sw.dom.removeClass(noScrollClass);
        }
        return false;
    });
};

export default mobileNavigation;
