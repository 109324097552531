/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

// mobileSlider
import sliderCallback from './sliderCallback';

const mobileSlider = () => {
    let hiddenClass = sw.hiddenClass;
    if (sw.isMobile || sw.dom.hasClass('grid-filter')) {
        if ($.cookie('titles-off') === '1') {
            hiddenClass = '';
        } else {
            hiddenClass = sw.hiddenClass;
        }
    }

    sw.slider = $('.slider');
    sw.target;
    sw.next = $('.next');
    sw.prev = $('.prev');
    sw.imgThumbs.each(function () {
        sw.eaSrc = $(this).attr('href').split('/')[1];
        sw.eaAlt = $(this).find('img').attr('data-alt');
        if (sw.eaSrc.match('.mp4')) {
            sw.slider.append(
                `<li><video preload='none' tabindex='-1' loop autoplay><source src="img-m/${sw.eaSrc}" type="video/mp4"></video></li>`
            );
        } else {
            sw.slider.append(`<li><img src="img-l/${sw.eaSrc}"/></li>`);
        }
        sw.images = sw.slider.find('li');
        sw.lastElem = sw.slider.find('li').length - 1;
    });
    sliderCallback();

    const toggleImageInfo = function () {
        const metaTitle = $('.meta-title');
        $('.toggle-image-info').on('click', function () {
            if (metaTitle.hasClass(sw.hiddenClass)) {
                metaTitle.removeClass(sw.hiddenClass);
                $.cookie('titles-off', '1', { expires: 365, path: '/' });
            } else {
                metaTitle.addClass(sw.hiddenClass);
                $.cookie('titles-off', null, { expires: 365, path: '/' });
            }
            return false;
        });
    };
    toggleImageInfo();

    // set up swiping for mobile view.
    const initSwipe = () => {
        sw.images.swipe({
            fingers: 'all',
            swipeLeft: swipe1,
            swipeRight: swipe2,
            allowPageScroll: 'auto'
        });

        //swipe handlers.
        function swipe1() {
            sw.target = $('.slider li.active').index();
            if (sw.target === sw.lastElem) {
                sw.target = 0;
            } else {
                sw.target = sw.target + 1;
            }
            sliderResponse(sw.target);
            sliderCallback();
        }

        function swipe2() {
            sw.target = $('.slider li.active').index();
            sw.lastElem = $('.slider li').length - 1;
            if (sw.target === 0) {
                sw.target = sw.lastElem;
            } else {
                sw.target = sw.target - 1;
            }
            sliderResponse(sw.target);
            sliderCallback();
        }

        const keyNavigation = function () {
            $(sw.body).on('keydown', function (e) {
                if (sw.sliderActive) {
                    if (e.keyCode == 39) {
                        swipe1();
                    } else if (e.keyCode == 37) {
                        swipe2();
                    }
                }
            });
        };
        keyNavigation();
    };
    initSwipe();

    // fadein/out images in slider
    const sliderResponse = () => {
        sw.images
            .removeClass('active')
            .hide()
            .eq(sw.target)
            .fadeIn(sw._site.speed.slow)
            .addClass('active')
            .show();
    };
};

export default mobileSlider;
