/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

import sliderCallback from './sliderCallback';

// load large images into slider for mobile
const loadMImagesMobile = () => {
    const activeClass = 'active';
    $('img').on('load', () => {
        sw.negativeClass = 'negative';
        sw.imgThumbs.find(`.${sw.negativeClass}`).each(function () {
            sw.LI = $(this).parent().parent();
            sw.images.eq(sw.LI.index()).addClass(sw.negativeClass);
        });
    });
    if (sw.isMobile) {
        sw.imgThumbs.on('click', function () {
            sw.sliderActive = true;
            sw.sliderWrapper.show();
            sw.listItem = $(this).parent();
            sw.listItemIndex = sw.listItem.index();
            sw.sliderImg = sw.images.index(sw.listItemIndex);
            sw.images
                .removeClass(activeClass)
                .eq(sw.listItemIndex)
                .fadeIn(sw._site.speed.fast)
                .addClass(activeClass)
                .css('display', 'block');
            sliderCallback();
            sw.dom.addClass('no-scroll');
            return false;
        });
    }
};

export default loadMImagesMobile;
