/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

// hover on thumbnails
const hoverLinks = () => {
    const hoverClass = 'hover';
    sw.imgThumbs.on('mouseenter', function () {
        $(this).parent().siblings().addClass(hoverClass);
    });
    sw.imgThumbs.on('mouseleave', function () {
        $(this).parent().siblings().removeClass(hoverClass);
    });
};

export default hoverLinks;
