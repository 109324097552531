/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

// zoetrope
const zoetrope = () => {
    // globals
    const wrapper = document.querySelector('.wrapper');
    const video = document.querySelector('.video');
    const video2 = document.querySelector('.video2');
    const loadingClass = 'loading';
    const getRandomInt = (min, max) => Math.random() * (max - min) + min;
    let randomInterval;
    let rndDegree;
    video.muted = true;
    video.play();
    video2.muted = true;
    video2.play();

    // loader
    window.addEventListener('load', afterLoaded, false);
    function afterLoaded() {
        wrapper.classList.remove(loadingClass);
    }

    // load random images and flash them
    function setRandomImage() {
        const randomIndex = parseInt(Math.random() * $('#posts li').length);
        // remove block class from all elems.
        $('#posts img').removeClass('block');
        // add block class to random item.
        $(`#posts li:eq(${randomIndex}) img`).addClass('block');
        setDelay();
    }

    for (let i = 1; i <= randomInterval; ++i) {
        setDelay(i);
    }

    // create a delay function using a random integer
    function setDelay(i) {
        randomInterval = getRandomInt(2000, 8000);
        rndDegree = Math.floor(Math.random() * 360);
        setTimeout(function () {
            setRandomImage();
            updateSpeed();
        }, randomInterval);
    }

    // set random opacity for video
    const randomInt = () => getRandomInt(0.5, 0.65);
    const updateOpacity = () => (video.style.opacity = randomInt());
    const updateSpeed = () => (video2.playbackRate = getRandomInt(0.1, 1.5));
    const updateFilter = () =>
        $(video2).css({
            filter: `hue-rotate(${rndDegree}deg) saturate(${getRandomInt(2, 5)})`
        });
    const runTimer = () => setInterval(updateOpacity, 200);
    const runFilterTimer = () => setInterval(updateFilter, 1200);

    runTimer();
    runFilterTimer();
    setDelay();
};

export { zoetrope };
