/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

// pagination for blog page
const pagination = () => {
    $(sw.body).on('keydown', function (event) {
        const next = $('.next')[0];
        const prev = $('.prev')[0];
        if (next && event.which == 39) {
            next.click();
        }
        if (prev && event.which == 37) {
            if (sw.pageNo === 0) {
                return;
            } else {
                prev.click();
            }
        }
    });
};

export default pagination;
