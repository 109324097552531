/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

import { deleteAllCookies, clearStorage } from './utils';

// cookie compliance
const $cookieWrapper = $('.cookie-wrapper');
const $trigger = $cookieWrapper.find('.accept-cookies');
const $declineCookies = $cookieWrapper.find('.decline-cookies');
const $cookieSettings = $('.cookie-settings');
const cookieClass = 'cookie-compliance';

const cookies = () => {
    if (!sw.isBlog) {
        $trigger.on('click', () => {
            $cookieWrapper.fadeOut('fast');
            sw.dom.removeClass(cookieClass);
            $.cookie('cookie-compliance', '1', { expires: 365, path: '/' });
            return false;
        });
        $cookieSettings.on('click', () => {
            $cookieWrapper.fadeIn('fast');
            sw.dom.addClass(cookieClass);
            return false;
        });
        $declineCookies.on('click', () => {
            deleteAllCookies();
            clearStorage();
            window.location = $declineCookies.attr('href');
        });
        $.cookie('cookie-compliance') !== '1'
            ? sw.dom.addClass(cookieClass)
            : sw.dom.removeClass(cookieClass);
    }
};

export default cookies;
