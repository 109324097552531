/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

// randomFilter
import { getRandomInt } from './utils';
const postContent = document.querySelector('.posts');
const pageWrapper = $('#pageWrapper');
const elem = $('.overlay');
const stackClass = 'stack';
const $trigger = $('.dual .retrieveData');
const limit = 1;
let index = 1;

const randomFilter = () => {
    const protocol = document.location.protocol;
    const baseURL = 'api.tumblr.com/v2/blog/';
    const blog = 'studio-lukeharby.tumblr.com';
    $.ajax({
        type: 'GET',
        url: `${protocol}//${baseURL}${blog}/posts`,
        dataType: 'jsonp',
        data: {
            api_key: sw._site.tumblrAPIKey
        },
        success: function (data) {
            let randomNumber;
            randomNumber = Math.floor(Math.random() * data.response.total_posts);
            $.ajax({
                type: 'GET',
                url: `${protocol}//${baseURL}${blog}/posts`,
                dataType: 'jsonp',
                data: {
                    api_key: sw._site.tumblrAPIKey,
                    offset: randomNumber,
                    limit: limit
                },
                success: function (data) {
                    for (let i = 0; i < data.response.posts.length; i++) {
                        const content = data.response.posts[i];
                        const isNPF = content.is_blocks_post_format;
                        if (isNPF) {
                            const body = content.body;
                            const parser = new DOMParser();
                            const doc = parser.parseFromString(body, 'text/html');
                            const img = doc.querySelectorAll('img');
                            var documentFragment = document.createDocumentFragment();
                            img.forEach((item) => {
                                const li = document.createElement('li');
                                li.appendChild(item);
                                documentFragment.appendChild(li);
                            });
                            postContent.appendChild(documentFragment);
                        } else {
                            $(postContent).append(`
                                <li><img src=${content.photos[i].original_size.url} /></li>
                            `);
                        }
                        setImgProps();
                        setWrapperProps();
                        imgLoader();
                    }
                },
                complete: function () {
                    initClick();
                }
            });
        },
        error: function (error) {
            pageWrapper.append(
                `<p class="content">Sorry there was an issue retrieving the data. ${error.statusText}</p>`
            );
            $(postContent).remove();
            elem.removeClass(sw.loadingClass);
        }
    });
};

const setImgProps = () => {
    const images = $(postContent).find('img');
    images.each(function () {
        const rndNumber = Math.floor(Math.random() * 360);
        const rndInt = getRandomInt(1, 8);
        $(this)
            .css({
                filter: `hue-rotate(${rndNumber}deg)`
            })
            .delay(index * 333)
            .fadeIn(rndInt + '000');
    });
};

const setWrapperProps = () => {
    const firstItem = $(postContent).find('img:first');
    const lastItem = $(postContent).find('img:last');
    const rndInt1 = getRandomInt(11, 27);
    let rndInt2;
    if (!sw.isMobile) {
        rndInt2 = getRandomInt(10, 43);
    } else {
        rndInt2 = getRandomInt(53, 79);
    }
    firstItem.css({
        'margin-left': `${rndInt1}%`,
        'margin-top': `${rndInt2}%`
    });
    lastItem.css({
        'margin-left': `-${rndInt1}%`,
        'margin-top': `${rndInt2 * 1.5}%`
    });
};

const imgLoader = function () {
    const images = $(postContent).find('img');
    const loadingClass = 'ajax-loading';
    elem.addClass(loadingClass);
    images.on('load', () => {
        elem.removeClass(loadingClass);
    });
};

const initClick = () => {
    const items = $(postContent).find('li');
    items.eq(0).addClass(stackClass);
    items.off().on('click', () => {
        const self = $(event.target).parent();
        self.hasClass(stackClass)
            ? (self.removeClass(stackClass), self.siblings().addClass(stackClass))
            : (self.siblings().removeClass(stackClass), self.addClass(stackClass));
    });
};

const initCounter = () => {
    while (index <= 1) {
        index++;
        randomFilter();
    }
};

if (sw.dom.hasClass('dual')) {
    $(postContent).empty();
    imgLoader();
    initCounter();
}

$trigger.on('click', () => {
    index = 0;
    $(postContent).empty();
    initCounter();
    return false;
});

export { randomFilter };
