/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

// sliderCallback
const sliderCallback = () => {
    sw.target = $('.slider li.active').index() + 1;
    var currentIndex = $('.currentIndex');
    var totalIndex = $('.totalIndex');
    totalIndex.text(sw.slider.find('li').length);
    if (sw.target < 10) {
        sw.target = `0${sw.target}`;
    }
    currentIndex.text(sw.target);
};

export default sliderCallback;
