/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

// refresh doc
const refresh = () => {
    const refresh = $('.refresh');
    refresh.on('click', function () {
        location.reload();
    });
};

export default refresh;
