/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

// decode blog urls
const blogLinks = () => {
    if (sw.isBlog) {
        sw.descriptionLinks = $('[href*="href.li"]');
        sw.redirectLinks = $('[href*=redirect]');
        sw.redirectLinks.each(function () {
            let newURL = $(this).attr('href').split('=')[1];
            newURL = decodeURIComponent(newURL);
            $(this).attr('href', newURL).attr('target', '_blank');
        });
        sw.descriptionLinks.each(function () {
            const newURL = $(this).attr('href').split('?')[1];
            $(this).attr('href', newURL).attr('target', '_blank');
        });
    }
};

export default blogLinks;
