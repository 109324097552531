/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

// shop add cta text
const shopCTAText = () => {
    const products = $('.products .outofstock');
    products.each(function () {
        $(this).find('.button').text('Read more');
    });
};

export default shopCTAText;
