/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

// delete media
import { hideOverlay } from './closeOverlay';

const url = '../../includes/delete-media.cfm';

document.addEventListener('click', (event) => {
    if (event.target.classList.contains('cancel-close')) {
        event.preventDefault();
        hideOverlay();
    }
});

$(document).on('click', '.confirm-delete', () => {
    const fileName = $('.modal-wrapper').find('.file-name').text();
    $.ajax({
        url,
        type: 'POST',
        data: 'fileName=' + fileName,
        success() {
            hideOverlay();
            location.reload();
        },
        error(status) {
            console.log(status.statusText);
        }
    });
    return false;
});
