/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

// utils
import detachFooter from './detachFooter';

const utils = () => {
    sw.dom.removeClass('no-js');
    if (sw.href.match('dev.slackwise.org.uk')) {
        console.log(sw);
    }
    $(window).on('load resize orientationchange', () => {
        detachFooter();
    });
};

const randomNumber = () => {
    const rndNum = Math.round(Math.random());
    return rndNum;
};

const getRandomInt = (min, max) => {
    return Math.random() * (max - min) + min;
};

const isValidField = (input, trigger) => {
    if (input.value.length <= 0) {
        trigger.classList.add(sw.disabledClass);
    } else {
        trigger.classList.remove(sw.disabledClass);
    }
};

// Restrict invalid character
// from form input
const restrictIllegalChars = (input) => {
    input.on('input', (e) => {
        const regex = new RegExp('\\.*[$%^&()`\\~\\{\\}\\[\\]\\|/<>\\\\].*', 'g');
        const value = e.target.value;
        const newValue = value.replace(regex, '');
        if (newValue !== value) {
            e.target.value = newValue;
        }
    });
};

// Restrict entering 'e' key
// from number input
const integerInput = function () {
    const input = $('.pay-amount');
    input.on('input', (event) => {
        const $self = $(event.target);
        const numberInput = $('[type="number"]');
        if ($self.is(numberInput) && event.keyCode === 69) {
            return false;
        }
    });
};

const checkFeatureStorage = () => {
    const wrapper = $('#pageWrapper');
    if (!window.localStorage) {
        wrapper.prepend('<p>This functionality is not available in your current browser</p>');
    }
};

const isEmptyObject = (obj) => {
    for (var key in obj) {
        if (obj.hasOwnProperty.call(key)) {
            return false;
        }
    }
    return true;
};

// add to local storage
const addToLocalStorageObject = function (name, key, value) {
    // Get the existing data
    let existing = localStorage.getItem(name);
    // If no existing data, create an array
    // Otherwise, convert the localStorage string to an array
    existing = existing ? JSON.parse(existing) : {};
    // Add new data to localStorage Array
    existing[key] = value;
    // Save back to localStorage
    localStorage.setItem(name, JSON.stringify(existing));
};

// retrieve from local storage
const retrieveFromLocalStorageObject = function (name) {
    let data = localStorage.getItem(name);
    return data ? JSON.parse(data) : null;
};

// remove from local storage
const removeFromLocalStorage = function (name, productName) {
    const getObjFromLocalStorage = JSON.parse(localStorage.getItem(name));
    delete getObjFromLocalStorage[productName];
    localStorage.setItem(name, JSON.stringify(getObjFromLocalStorage));
    console.log(getObjFromLocalStorage);
};

const enterFullscreen = (id) => {
    const element = document.getElementById(id);
    if (element.requestFullscreen) {
        element.requestFullscreen();
    } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
    } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
    }
};

const exitFullscreen = () => {
    if (document.exitFullscreen) {
        document.exitFullscreen();
    } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
    } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
    }
};

/**
 * Makes an element draggable.
 *
 * @param {HTMLElement} element - The element.
 */
const draggable = (element) => {
    var isMouseDown = false;

    // initial mouse X and Y for `mousedown`
    var mouseX;
    var mouseY;

    // element X and Y before and after move
    var elementX = 0;
    var elementY = 0;

    // mouse button down over the element
    element.addEventListener('mousedown', onMouseDown);

    /**
     * Listens to `mousedown` event.
     *
     * @param {Object} event - The event.
     */
    function onMouseDown(event) {
        mouseX = event.clientX;
        mouseY = event.clientY;
        isMouseDown = true;
    }

    // mouse button released
    element.addEventListener('mouseup', onMouseUp);

    /**
     * Listens to `mouseup` event.
     *
     * @param {Object} event - The event.
     */
    function onMouseUp(event) {
        isMouseDown = false;
        elementX = parseInt(element.style.left) || 0;
        elementY = parseInt(element.style.top) || 0;
    }

    // need to attach to the entire document
    // in order to take full width and height
    // this ensures the element keeps up with the mouse
    document.addEventListener('mousemove', onMouseMove);

    /**
     * Listens to `mousemove` event.
     *
     * @param {Object} event - The event.
     */
    function onMouseMove(event) {
        if (!isMouseDown) {
            return;
        }
        var deltaX = event.clientX - mouseX;
        var deltaY = event.clientY - mouseY;
        element.style.left = elementX + deltaX + 'px';
        element.style.top = elementY + deltaY + 'px';
    }
};

const deleteAllCookies = () => {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i];
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
};

const clearStorage = () => {
    localStorage.clear();
    sessionStorage.clear();
};

checkFeatureStorage();

export {
    utils,
    randomNumber,
    getRandomInt,
    isValidField,
    restrictIllegalChars,
    integerInput,
    checkFeatureStorage,
    isEmptyObject,
    addToLocalStorageObject,
    retrieveFromLocalStorageObject,
    removeFromLocalStorage,
    enterFullscreen,
    exitFullscreen,
    draggable,
    deleteAllCookies,
    clearStorage
};
