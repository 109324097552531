/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

// close lightbox
const closeOverlay = () => {
    sw.closeX = $('#closeWindow');
    sw.closeImg = $('.overlay img');
    sw.close = $.merge(sw.closeX, sw.closeImg);
    sw.close.on('click', () => {
        hideOverlay();
        return false;
    });
    $(sw.body).on('keydown', (e) => {
        if ($('.overlay img').attr('src') !== '') {
            if (e.keyCode === 27) {
                hideOverlay();
            }
        }
    });
};

const hideOverlay = () => {
    sw.overlay.removeClass(sw.loadingClass);
    sw.overlayImg.attr('src', '');
    sw.overlay.find('.modal').remove(); // TODO does this exist in the DOM?
    sw.dom.removeClass('no-scroll');
};

export { closeOverlay, hideOverlay };
