/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

// ajax loader
const ajaxLoader = (elem, className) => {
    $(document)
        .on('ajaxStart', () => {
            elem.addClass(className);
        })
        .on('ajaxStop', () => {
            elem.removeClass(className);
        });
};

export default ajaxLoader;