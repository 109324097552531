/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

// loadImages
import { randomNumber } from './utils';

// load medium sized images
const loadImages = () => {
    if (sw.imgThumbs.length) {
        sw.aTag = sw.firstImgThumb.attr('href');
        sw.firstAlt = sw.firstImgThumb.find('img').attr('alt');
        sw.path = sw.aTag.replace('img-s', 'img-m');
        sw.imgTarget.attr('src', sw.path).attr('alt', sw.firstAlt).parent().attr('href', sw.path);
        sw.holder = $('.target');
        sw.holder.text(sw.firstMetaData);
        sw.negativeClass = 'negative';
        sw.negative
            ? sw.imgThumbs.find('img').each(function () {
                  if (randomNumber() === 0) {
                      $(this).addClass(sw.negativeClass);
                  }
              })
            : null;
        if ($(sw.firstImgThumb).find('img').hasClass(sw.negativeClass)) {
            sw.imgTarget.addClass(sw.negativeClass);
        }
        sw.imgThumbs.off().on('click', function () {
            sw.aTag = $(this).attr('href');
            sw.path = sw.aTag.replace('img-s', 'img-m');
            sw.alt = $(this)
                .find('img')
                .attr('alt' || 'data-alt');
            sw.metaData = $(this).next('.meta-data').text();
            sw.imgTarget.attr('src', '').addClass(sw.loadingClass);
            $.ajax({
                type: 'GET',
                url: `${sw.path}`,
                xhrFields: {
                    responseType: 'blob'
                },
                success(data) {
                    const urlObj = window.URL || window.webkitURL;
                    const src = urlObj.createObjectURL(data);
                    sw.imgTarget
                        .attr('src', src)
                        .removeClass(sw.loadingClass)
                        .attr('alt', sw.alt)
                        .parent()
                        .attr('href', sw.path);
                },
                error(status) {
                    console.log(status.statusText);
                }
            });
            $(this).find('img').hasClass(sw.negativeClass)
                ? sw.imgTarget.addClass(sw.negativeClass)
                : sw.imgTarget.removeClass(sw.negativeClass);
            sw.holder.empty();
            sw.holder.text(sw.metaData);
            return false;
        });
    }
};

export default loadImages;
