/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

// utils
import ajaxLoader from './ajaxLoader';

const siteUtils = () => {
    const elem = $('.masthead');
    ajaxLoader(elem, sw.loadingClass);
};

export default siteUtils;
