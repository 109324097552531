/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

import ajaxLoader from './ajaxLoader';

// modal
const initModal = () => {
    const wrapper = $('#pageWrapper');
    const folder = '/includes/';
    wrapper.on('click', '.modal', function () {
        const source = $(this).attr('href');
        sw.overlay.find('> div').remove();
        sw.overlay.addClass(sw.loadingClass);
        const fileContent = folder + source;
        $.get(fileContent, function (data) {
            $(sw.overlay).append(data);
        });
        sw.dom.addClass('no-scroll');

        if ($('[class*=delete-modal]', $(this))) {
            const name = $(this).closest('li').find('.name').text();
            $(document).ajaxStop(() => {
                $('.modal-wrapper').find('.file-name').text(name);
            });
        }
        return false;
    });

    $(sw.body).on('keydown', (e) => {
        if (e.keyCode === 27) {
            hideOverlay();
        }
    });

    const hideOverlay = () => {
        sw.overlay.removeClass(sw.loadingClass);
        sw.overlayImg.attr('src', '');
        sw.overlay.find('.modal').remove();
        sw.dom.removeClass('no-scroll');
    };

    const modalAjaxLoader = () => {
        const elem = $('.overlay');
        const loadingClass = 'ajax-loading';
        ajaxLoader(elem, loadingClass);
    };

    if ($('.modal').length) {
        modalAjaxLoader();
    }
};

export default initModal;
