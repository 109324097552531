/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

// accordion
const accordion = () => {
    const wrapper = $('.accordion');
    const trigger = wrapper.find('> li > a');
    const openClass = 'open';
    trigger.on('click', (event) => {
        event.preventDefault();
        if ($(event.target).hasClass(openClass)) {
            $(event.target).removeClass(openClass);
        } else {
            $(event.target).addClass(openClass);
        }
    });
};

export default accordion;