/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

import { enterFullscreen, exitFullscreen } from './utils';

// chrome window
const chromeWindow = () => {
    const wrapper = $('.video-content');
    const imgWrapper = sw.imgTarget.parent();
    const onClass = 'on';
    const offClass = 'off';
    const entry = $('.chrome-window .entry');
    if (sw.firstImgThumb.attr('href').match('.mp4')) {
        wrapper.empty().addClass(onClass).removeClass(offClass);
        imgWrapper.addClass(sw.hiddenClass);
        wrapper.removeClass(sw.hiddenClass);
        wrapper.append(
            `<a href='' class='ion-android-expand'></a>
            <video id="videoWrapper" preload='none' tabindex='-1' muted loop autoplay>
                <source src=${sw.firstImgThumb.attr('href')} type='video/mp4'>
            </video>`
        );
    }
    sw.imgThumbs.on('click', (e) => {
        e.preventDefault();
        const anchor = $(e.target).closest('a');
        const href = anchor.attr('href');
        wrapper.empty().addClass(onClass).removeClass(offClass);
        if (!href.match('.jpg')) {
            imgWrapper.addClass(sw.hiddenClass);
            wrapper.removeClass(sw.hiddenClass);
            wrapper.append(
                `<a href='' class='ion-android-expand'></a>
                <video id="videoWrapper" preload='none' tabindex='-1' muted loop autoplay>
                    <source src=${href} type='video/mp4'>
                </video>`
            );
        } else {
            imgWrapper.removeClass(sw.hiddenClass);
            wrapper.addClass(sw.hiddenClass);
        }
    });
    wrapper.on('click', (e) => {
        // add key event to toggle active state
        e.preventDefault();
        const onOrOff = wrapper.hasClass(onClass);
        if (onOrOff) {
            enterFullscreen('videoWrapper');
            wrapper.removeClass(onClass).addClass(offClass);
        } else {
            exitFullscreen();
            wrapper.addClass(onClass).removeClass(offClass);
        }
    });
    if (document.addEventListener) {
        document.addEventListener('fullscreenchange', exitHandler, false);
        document.addEventListener('mozfullscreenchange', exitHandler, false);
        document.addEventListener('MSFullscreenChange', exitHandler, false);
        document.addEventListener('webkitfullscreenchange', exitHandler, false);
    }
    function exitHandler() {
        if (
            !document.webkitIsFullScreen &&
            !document.mozFullScreen &&
            !document.msFullscreenElement
        ) {
            wrapper.addClass(onClass).removeClass(offClass);
        }
    }
    entry.on('click', '.site-link', (event) => {
        event.preventDefault();
        $(event.target).closest('.entry').addClass(sw.hiddenClass);
        $.cookie('chrome-window-entry', '1', { expires: 1, path: '/' });
    });

    if (typeof $.cookie === 'function') {
        if ($.cookie('chrome-window-entry') !== '1') {
            entry.removeClass(sw.hiddenClass);
        } else {
            entry.addClass(sw.hiddenClass);
        }
    }
};

export { chromeWindow };
