/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

// legalTrigger
const legalTrigger = () => {
    const legalTrigger = $('.legal-trigger');
    legalTrigger.on('click', function () {
        const minusClass = 'nav-minus';
        const plusClass = 'nav-plus';
        const legalInfo = $(this).parent().prev();
        if ($(this).hasClass(plusClass)) {
            $(this).addClass(minusClass).removeClass(plusClass);
            legalInfo.removeClass(sw.hiddenClass);
        } else {
            $(this).addClass(plusClass).removeClass(minusClass);
            legalInfo.addClass(sw.hiddenClass);
        }
        return false;
    });
};

export default legalTrigger;
